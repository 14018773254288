import React, { useState, useEffect } from 'react';
import {
  Form,
  Input,
  InputNumber,
  Button,
  Select,
  Checkbox,
  DatePicker,
  Upload,
  Divider,
  Tooltip,
  Space,
  Typography,
  message,
  Modal,
} from 'antd';
import {
  PlusOutlined,
  MinusCircleOutlined,
  UploadOutlined,
  InfoCircleOutlined,
} from '@ant-design/icons';
import ReactAce from 'react-ace';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-cobalt';

const { Option } = Select;
const { Text } = Typography;

function ApiForm({ selectedApi, onRunRequest, onPreviewRequest }) {
  const [form] = Form.useForm();
  const [isLoading, setIsLoading] = useState(false);
  const [jsonModal, setJsonModal] = useState({
    visible: false,
    fieldName: [],
    dataType: '',
  });
  const [jsonValue, setJsonValue] = useState('{}');
  const [jsonFieldLabel, setJsonFieldLabel] = useState('');
  // Add this to your component's state
const [arrayFieldValues, setArrayFieldValues] = useState({});


  useEffect(() => {
    form.resetFields();
  }, [selectedApi]);

  const setNestedValue = (obj, path, value) => {
    let current = obj;
    for (let i = 0; i < path.length; i++) {
      const key = path[i];
      if (i === path.length - 1) {
        current[key] = value;
      } else {
        if (!current[key]) current[key] = {};
        current = current[key];
      }
    }
  };

  const getNestedValue = (obj, path) => {
    return path.reduce(
      (acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined),
      obj
    );
  };

  const handleRunRequest = async () => {
    try {
      setIsLoading(true);
      const values = await form.validateFields();
      const requestData = buildRequestData(values, selectedApi.parameters, selectedApi);
      await onRunRequest(requestData);
    } catch (error) {
      console.error('Error during form validation or API call:', error);
      message.error('Failed to run the request. Please check the input fields.');
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviewRequest = async () => {
    try {
      const values = await form.validateFields();
      const requestData = buildRequestData(values, selectedApi.parameters, selectedApi);
      await onPreviewRequest(requestData);
    } catch (error) {
      console.error('Error during form validation or preview:', error);
      message.error('Failed to preview the request. Please check the input fields.');
    }
  };

  const buildRequestData = (values, parameters, selectedApi) => {
    const requestData = {};

     // Helper function to filter out null and empty values
     const filterEmptyValues = (obj) => {
      if (Array.isArray(obj)) {
        return obj.map((item) => filterEmptyValues(item)); // Recursively apply to array items
      } else if (typeof obj === 'object' && obj !== null) {
        return Object.entries(obj).reduce((acc, [key, value]) => {
          if (value !== null && value !== undefined && value !== '') {
            acc[key] = typeof value === 'object' ? filterEmptyValues(value) : value;
          }
          return acc;
        }, {});
      }
      return obj;
    };
    

    // Initialize sections
    const queryParams = {};
    const pathParams = {};
    const headerParams = {};
    const bodyParams = {};
    const formDataParams = {};

    // Handle parameters
    parameters.forEach((param, index) => {
      const includeParam = getNestedValue(values, ['include', param.parameterType, param.name, `param_${index}`]);
      if (includeParam) {
        let value = getNestedValue(values, ['values', param.parameterType, param.name, `param_${index}`]);
      
        if (value !== undefined && value !== null && value !== '') {
        // Handle date conversion
        if (param.dataType === 'date' && value) {
          value = value.format('YYYY-MM-DD');
        }

        const paramType = param.parameterType;
        if (paramType === 'query') {
          queryParams[param.name] = value;
        } else if (paramType === 'path') {
          pathParams[param.name] = value;
        } else if (paramType === 'header') {
          headerParams[param.name] = value;
        } else if (paramType === 'body') {
          if (param.fieldPath && param.fieldPath.length > 0) {
            setNestedValue(bodyParams, param.fieldPath, value);
          } else {
            bodyParams[param.name] = value;
          }
        }
       
        else if (paramType === 'form-data') {
          if (param.formDataFields) {
            param.formDataFields.forEach((field) => {
              const includeField = getNestedValue(values, ['include', param.parameterType, param.name, `param_${index}`, field.fieldName]);
              
              if (includeField) {  // Check if the field is selected
                const fieldValue = getNestedValue(values, ['values', param.parameterType, param.name, `param_${index}`, field.fieldName]);
                
                if (field.fieldType === 'file') {
                  formDataParams[field.fieldName] = fieldValue?.[0]?.originFileObj;
                } else {
                  formDataParams[field.fieldName] = fieldValue;
                }
              }
            });
          } else {
            formDataParams[param.name] = value;
          }
        }
      }
    }
  });

   // Apply filter to remove empty or null values
   const filteredQueryParams = filterEmptyValues(queryParams);
   const filteredPathParams = filterEmptyValues(pathParams);
   const filteredHeaderParams = filterEmptyValues(headerParams);
   const filteredBodyParams = filterEmptyValues(bodyParams);
   const filteredFormDataParams = filterEmptyValues(formDataParams);
 

    // Assign sections to requestData if they have values

  // Assign filtered sections to requestData if they have values
  if (Object.keys(filteredQueryParams).length > 0) {
    requestData.query = filteredQueryParams;
  }
  if (Object.keys(filteredPathParams).length > 0) {
    requestData.path = filteredPathParams;
  }
  if (Object.keys(filteredHeaderParams).length > 0) {
    requestData.headers = filteredHeaderParams;
  }
  if (Object.keys(filteredBodyParams).length > 0) {
    requestData.body = filteredBodyParams;
  }
  if (Object.keys(filteredFormDataParams).length > 0) {
    requestData.formData = filteredFormDataParams;
  }


    // Handle attributes
    if (selectedApi?.attributes && selectedApi.attributes.length > 0) {
      const selectedAttributes = [];
      selectedApi.attributes.forEach((attr, index) => {
        const isSelected = values.attributes?.[`attr_${index}`];
        if (isSelected) {
          selectedAttributes.push(attr.name);
        }
      });
      if (selectedAttributes.length > 0) {
        requestData.attributes = selectedAttributes;
      }
    }

    return requestData;
  };


  const renderField = (param, fieldPath, index, isNested = false) => {
    const dataType = param.dataType || param.fieldType;
    const fieldName = [...fieldPath, `param_${index}`];
    const label = (
      <span>
        {param.name || param.fieldName}{' '}
        {param.description && (
          <Tooltip title={param.description}      placement="right"    overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
          color="#f5f5f5">
            <InfoCircleOutlined />
          </Tooltip>
        )}{' '}
        <Text type="secondary">({dataType})</Text>
      </span>
    );

    const uniqueKey = fieldName.join('-');

    if (!isNested) {
      // For optional parameters, wrap the field in a Form.Item with a Checkbox
      return (
        <div key={uniqueKey} style={{ marginBottom: '16px' }}>
          <Space align="baseline">
            <Form.Item
              name={['include', ...fieldPath, `param_${index}`]}
              valuePropName="checked"
              initialValue={false}
              noStyle
            >
              <Checkbox />
            </Form.Item>
            <label>{label}</label>
          </Space>
          <Form.Item
            shouldUpdate={(prevValues, curValues) => {
              return (
                getNestedValue(prevValues, ['include', ...fieldPath, `param_${index}`]) !==
                getNestedValue(curValues, ['include', ...fieldPath, `param_${index}`])
              );
            }}
            noStyle
          >
            {() => {
              const includeParam = getNestedValue(form.getFieldsValue(), ['include', ...fieldPath, `param_${index}`]);
              if (includeParam) {
                return (
                  <div style={{ marginLeft: '24px', marginTop: '8px' }}>
                    {renderInputField(param, fieldName, index)}
                  </div>
                );
              }
              return null;
            }}
          </Form.Item>
        </div>
      );
    } else {
      // For nested fields, render the input field directly
      return (
        <div
          key={uniqueKey}
          style={{ marginBottom: '16px', marginLeft: '24px' }}
        >
          <label>{label}</label>
          {renderInputField(param, fieldName, index)}
        </div>
      );
    }
  };

  const renderInputField = (param, fieldName, index) => {
    const dataType = param.dataType || param.fieldType;
    const rules = [];


    switch (dataType) {
      case 'string':
        return (
          <Form.Item
            name={['values', ...fieldName]}
            rules={rules}
            key={['values', ...fieldName].join('-')}
          >
            <Input placeholder={`Enter ${param.name || param.fieldName}`} />
          </Form.Item>
        );
        case 'number':
  return (
    <Form.Item
      name={['values', ...fieldName]}
      key={['values', ...fieldName].join('-')}
      rules={[
        {
          validator: (_, value) => {
            if (value === null || value === undefined || value === '') {
              return Promise.resolve();
            }
            if (typeof value === 'number') {
              return Promise.resolve();
            }
            return Promise.reject(new Error(`${param.name || param.fieldName} must be a number`));
          },
        },
      ]}
    >
      <InputNumber
        placeholder={`Enter ${param.name || param.fieldName}`}
        style={{ width: '100%' }}
        // Ensure empty string sets to null
        onChange={(value) => {
          form.setFieldsValue({
            values: {
              ...form.getFieldValue('values'),
              [fieldName.join('.')]: value === '' ? null : value,
            },
          });
        }}
        // Use a parser to handle empty input gracefully
        parser={(value) => (value === '' ? null : value)}
        // Ensure that an empty input does not show as "0" but as an empty field
        formatter={(value) => (value === null ? '' : value)}
      />
    </Form.Item>
  );

  case 'enum':
    return (
      <Form.Item
        name={['values', ...fieldName]}
        key={['values', ...fieldName].join('-')}
        rules={[
          { required: true, message: `${param.name || param.fieldName} is required` },
        ]}
      >
        <Select placeholder={`Select ${param.name || param.fieldName}`}>
          {param.enumValues.map((enumValue) => (
            <Option key={enumValue} value={enumValue}>
              {enumValue}
            </Option>
          ))}
        </Select>
      </Form.Item>
    );
      case 'boolean':
        return (
          <Form.Item
            name={['values', ...fieldName]}
            // rules={rules}
            key={['values', ...fieldName].join('-')}
          >
            <Select placeholder={`Select ${param.name || param.fieldName}`}>
              <Option value={true}>True</Option>
              <Option value={false}>False</Option>
            </Select>
          </Form.Item>
        );
      case 'date':
        return (
          <Form.Item
            name={['values', ...fieldName]}
            // rules={rules}
            key={['values', ...fieldName].join('-')}
          >
            <DatePicker
              style={{ width: '100%' }}
              format="YYYY-MM-DD"
              placeholder={`Select ${param.name || param.fieldName}`}
            />
          </Form.Item>
        );
      case 'array':
        return renderArrayField(param, fieldName, index);
      case 'object':
        return renderObjectField(param, fieldName, index);
      default:
        return null;
    }
  };

  const renderArrayField = (param, fieldName) => {
    const arrayItemType = param.arrayItemType;
    const schema = param.objectSchema || [];
  
    // If it's an array of objects or arrays without a schema, use ACE editor
    if ((arrayItemType === 'object' || arrayItemType === 'array') && schema.length === 0) {
      return (
        <Form.Item key={['values', ...fieldName].join('-')}>
          <Button
            onClick={() =>
              openJsonModal(fieldName, param.name || param.fieldName, 'array')
            }
          >
            Edit Array
          </Button>
        </Form.Item>
      );
    }
  
    // Render a simple tags input for arrays of basic types (e.g., string, number)
    if (arrayItemType !== 'object' && arrayItemType !== 'array') {
      return (
        <Form.Item
          name={['values', ...fieldName]}
          // rules={[{ required: true, message: `${param.name || param.fieldName} is required` }]}
          key={['values', ...fieldName].join('-')}
        >
          <Select
            mode="tags"
            style={{ width: '100%' }}
            placeholder={`Enter ${param.name || param.fieldName}`}
            tokenSeparators={[',']}
          />
        </Form.Item>
      );
    }
  
    // For array of objects with a schema, render each field according to its data type
    return (
      <Form.List name={['values', ...fieldName]}>
        {(fields, { add, remove }) => (
          <div>
           
            {fields.map(({ key, name, ...restField }) => (
              <div
                key={key}
                style={{
                  border: '1px solid #ccc',
                  padding: '10px',
                  marginBottom: '10px',
                  marginLeft: '24px',
                }}
              >
                <Space
                  align="baseline"
                  style={{ display: 'flex', justifyContent: 'space-between' }}
                >
                  <Text>
                    {param.name || param.fieldName} [{name + 1}]
                  </Text>
                  <Button
                    type="text"
                    onClick={() => remove(name)}
                    icon={<MinusCircleOutlined />}
                  />
                </Space>
  
                {/* Render child fields based on objectSchema */}
                {schema.length > 0
                  ? schema.map((childParam, childIndex) => {
                      const childName = childParam.fieldName || `Field ${childIndex + 1}`;
                      const dataType = childParam.dataType || childParam.fieldType || 'string';
                      const label = (
                        <span>
                          {childName}{' '}
                          {childParam.description && (
                            <Tooltip title={childParam.description}   placement="right"    overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
                            color="#f5f5f5">
                              <InfoCircleOutlined />
                            </Tooltip>
                          )}
                          <Text type="secondary"> ({dataType})</Text>
                        </span>
                      );
  
                      // Define validation rules based on data type
                      const rules = [];

                      if (dataType === 'number') {
                        rules.push({
                          validator: (_, value) => {
                            if (value === '' || value === undefined || value === null) {
                              return Promise.resolve();
                            }
                            if (typeof value === 'number') {
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error(`${childName} must be a number`));
                          },
                        });
                      
                      
                      
                      } else if (dataType === 'date') {
                        rules.push({
                          type: 'object',
                          message: `${childName} must be a valid date`,
                        });
                      } else if (dataType === 'boolean') {
                        rules.push({
                          type: 'boolean',
                          message: `${childName} must be true or false`,
                        });
                      }
  
                      // Choose appropriate input component based on data type
                      return (
                        <Form.Item
                          {...restField}
                          name={[name, childName]}
                          label={label}
                          key={`${key}-${childName}`}
                          rules={rules}
                        >
                          {dataType === 'number' ? (
                            <InputNumber placeholder={`Enter ${childName}`} style={{ width: '100%' }} />
                          ) : dataType === 'date' ? (
                            <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" placeholder={`Select ${childName}`} />
                          ) : dataType === 'boolean' ? (
                            <Select placeholder={`Select ${childName}`}>
                              <Option value={true}>True</Option>
                              <Option value={false}>False</Option>
                        
                            </Select>
                            ) : dataType === 'enum' ? (
                              <Select placeholder={`Select ${childName}`}>
                                {childParam.enumValues?.map((enumValue) => (
                                  <Option key={enumValue} value={enumValue}>
                                    {enumValue}
                                  </Option>
                                ))}
                              </Select>
                          ) : dataType === 'array' ? (
                            <Select
                              mode="tags"
                              style={{ width: '100%' }}
                              placeholder={`Enter ${childName}`}
                              tokenSeparators={[',']}
                            />
                          ) : dataType === 'object' ? (
                            <Button
                              onClick={() =>
                                openJsonModal([...fieldName, name, childName], childName, 'object')
                              }
                            >
                              Edit {childName}
                            </Button>
                          ) : (
                            <Input placeholder={`Enter ${childName}`} />
                          )}
                        </Form.Item>
                      );
                    })
                  : renderInputField(param, fieldName, name)}
              </div>
            ))}
            <Button
              type="dashed"
              onClick={() => add()}
              block
              icon={<PlusOutlined />}
            >
              Add {param.name}
            </Button>
          </div>
        )}
      </Form.List>
    );
  };
  

  const renderObjectField = (param, fieldName) => {
    const schema = param.objectSchema || [];
  
    if (schema.length === 0) {
      // Use ACE Editor for objects without a schema
      return (
        <Form.Item key={['values', ...fieldName].join('-')}>
          <Button
            onClick={() =>
              openJsonModal(fieldName, param.name || param.fieldName, 'object')
            }
          >
            Edit Object
          </Button>
        </Form.Item>
      );
    }
  
    // Render a list of fields for the object schema
    return (
      <div
        style={{
          border: '1px solid #ccc',
          padding: '10px',
          marginBottom: '10px',
          marginLeft: '24px',
        }}
        key={fieldName.join('-')}
      >
        {schema.map((childParam, childIndex) => {
          const childName = childParam.fieldName || `Field ${childIndex + 1}`;
          const childFieldName = [...fieldName, childName];
          const dataType = childParam.dataType || childParam.fieldType || 'string';
          const label = (
            <span>
              {childName}{' '}
              {childParam.description && (
                <Tooltip title={childParam.description}   placement="right"    overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
                color="#f5f5f5">
                  <InfoCircleOutlined />
                </Tooltip>
              )}
              <Text type="secondary"> ({dataType})</Text>
            </span>
          );
  
          const rules = [];
          if (dataType === 'number') {
            rules.push({
              validator: (_, value) => {
                if (value === '' || value === undefined || value === null) {
                  return Promise.resolve();
                }
                if (typeof value === 'number') {
                  return Promise.resolve();
                }
                return Promise.reject(new Error(`${childName} must be a number`));
              },
            });
          } else if (dataType === 'date') {
            rules.push({
              type: 'object',
              message: `${childName} must be a valid date`,
            });
          } else if (dataType === 'boolean') {
            rules.push({
              type: 'boolean',
              message: `${childName} must be true or false`,
            });
          }
  
          return (
            <Form.Item
              name={['values', ...childFieldName]}
              label={label}
              key={`${fieldName.join('-')}-${childName}`}
              rules={rules}
            >
              {dataType === 'number' ? (
                <InputNumber placeholder={`Enter ${childName}`} style={{ width: '100%' }} />
              ) : dataType === 'date' ? (
                <DatePicker style={{ width: '100%' }} format="YYYY-MM-DD" placeholder={`Select ${childName}`} />
              ) : dataType === 'boolean' ? (
                <Select placeholder={`Select ${childName}`}>
                  <Option value={true}>True</Option>
                  <Option value={false}>False</Option>
                </Select>
                ) : dataType === 'enum' ? (
                  <Select placeholder={`Select ${childName}`}>
                    {childParam.enumValues?.map((enumValue) => (
                      <Option key={enumValue} value={enumValue}>
                        {enumValue}
                      </Option>
                    ))}
                  </Select>
                
              ) : dataType === 'array' ? (
                renderArrayField(childParam, childFieldName)
              ) : dataType === 'object' ? (
                renderObjectField(childParam, childFieldName) // Recursively handle nested objects
              ) : (
                <Input placeholder={`Enter ${childName}`} />
              )}
            </Form.Item>
          );
        })}
      </div>
    );
  };
  

  const openJsonModal = (fieldName, fieldLabel, dataType) => {
    const currentValue = getNestedValue(form.getFieldsValue(), ['values', ...fieldName]) || '';
    const stringValue =
      typeof currentValue === 'object' ? JSON.stringify(currentValue, null, 2) : '';
    setJsonValue(stringValue);
    setJsonModal({ visible: true, fieldName, dataType });
    setJsonFieldLabel(fieldLabel);
  };

  const handleJsonOk = () => {
    try {
      const parsedValue = JSON.parse(jsonValue);
      const fieldPath = ['values', ...jsonModal.fieldName];
      const fieldsValue = form.getFieldsValue();

      setNestedValue(fieldsValue, fieldPath, parsedValue);

      form.setFieldsValue(fieldsValue);
      setJsonModal({ visible: false, fieldName: [], dataType: '' });
      setJsonValue('{}');
    } catch (error) {
      message.error('Invalid JSON format. Please correct it and try again.');
    }
  };

  const handleJsonCancel = () => {
    setJsonModal({ visible: false, fieldName: [], dataType: '' });
    setJsonValue('{}');
  };



  const renderFormDataFields = (param, fieldPath, index) => {
    if (!param.formDataFields) {
      return null;
    }
    
    const fieldName = [...fieldPath, `param_${index}`];
    
    return (
      <div key={fieldName.join('-')}>
        <Divider orientation="left">{param.name} (form-data)</Divider>
        
        {param.formDataFields.map((field, fieldIndex) => {
          const fieldLabel = (
            <span>
              {field.fieldName}{' '}
              {field.description && (
                <Tooltip title={field.description}   placement="right"    overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
                color="#f5f5f5">
                  <InfoCircleOutlined />
                </Tooltip>
              )}{' '}
              <Text type="secondary">({field.fieldType})</Text>
            </span>
          );
  
          const fieldKey = [...fieldName, field.fieldName].join('-');
          const fieldCheckboxPath = ['include', ...fieldName, field.fieldName];
          const fieldValuePath = ['values', ...fieldName, field.fieldName];
  
          return (
            <div key={fieldKey} style={{ marginBottom: '16px' }}>
              <Space align="baseline">
                <Form.Item
                  name={fieldCheckboxPath}
                  valuePropName="checked"
                  initialValue={false}
                  noStyle
                >
                  <Checkbox />
                </Form.Item>
                <label>{fieldLabel}</label>
              </Space>
  
              <Form.Item
                shouldUpdate={(prevValues, curValues) =>
                  getNestedValue(prevValues, fieldCheckboxPath) !==
                  getNestedValue(curValues, fieldCheckboxPath)
                }
                noStyle
              >
                {() => {
                  const includeField = getNestedValue(
                    form.getFieldsValue(),
                    fieldCheckboxPath
                  );
                  if (includeField) {
                    return (
                      <div style={{ marginLeft: '24px', marginTop: '8px' }}>
                        {field.fieldType === 'file' ? (
                          <Form.Item
                            name={fieldValuePath}
                            valuePropName="fileList"
                            getValueFromEvent={(e) =>
                              Array.isArray(e) ? e : e && e.fileList
                            }
                            key={fieldKey}
                          >
                            <Upload beforeUpload={() => false}>
                              <Button icon={<UploadOutlined />}>
                                Upload {field.fieldName}
                              </Button>
                            </Upload>
                          </Form.Item>
                        ) : (
                          <Form.Item
                            name={fieldValuePath}
                            key={fieldKey}
                          >
                            <Input placeholder={`Enter ${field.fieldName}`} />
                          </Form.Item>
                        )}
                      </div>
                    );
                  }
                  return null;
                }}
              </Form.Item>
            </div>
          );
        })}
      </div>
    );
  };
  

  const renderFormFields = () => {
    if (!selectedApi || !selectedApi.parameters) {
      return null;
    }

    // Assign field paths to parameters for easier access during data submission
    const parametersWithPaths = selectedApi.parameters.map((param, index) => ({
      ...param,
      fieldPath: [param.parameterType, param.name],
      key: `${param.parameterType}_${param.name}_${index}`,
    }));

   
  return (
    <>
      {parametersWithPaths.map((param, index) => {
        // Conditional Divider for each parameter type group
        const isQuery = param.parameterType === 'query';
        const isPath = param.parameterType === 'path';
        const isHeader = param.parameterType === 'header';
        const isBody = param.parameterType === 'body';
        const isFirstOfType = parametersWithPaths.findIndex(
          (p) => p.parameterType === param.parameterType
        ) === index;

        return (
        
<div key={param.key}>
  {isFirstOfType && (
    <Divider orientation="left">
      {isQuery
        ? "Query Parameters "
        : isPath
        ? "Path Parameters "
        : isHeader
        ? "Header Parameters "
        : isBody
        ? "Body Parameters "
        : param.parameterType === 'form-data'
        ? "Form Data Parameters "
        : "Parameters "}

      {/* Adding InfoCircleOutlined with Tooltip for each parameter type */}
      <Tooltip title={
        isQuery
          ? "Parameters included in the query string of the request URL."
          : isPath
          ? "Parameters embedded within the request path URL."
          : isHeader
          ? "Parameters sent in the header section of the request."
          : isBody
          ? "Parameters included in the request body."
          : param.parameterType === 'form-data'
          ? "Parameters sent as form data, typically used for file uploads."
          : "Additional parameters for the request."
      }  placement="right"    overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
      color="#f5f5f5">
        <InfoCircleOutlined />
      </Tooltip>
    </Divider>
  )}
  {param.parameterType !== 'form-data'
    ? renderField(param, param.fieldPath, index)
    : renderFormDataFields(param, param.fieldPath, index)}
</div>
        
        );
      })}
    </>
  );
};

  const renderAttributes = () => {
    if (!selectedApi?.attributes || selectedApi.attributes.length === 0) {
      return null;
    }

    return (
      <>
        <Divider orientation="left">Request Additional Attributes <Tooltip title="Attributes are optional additional properties that can be requested in API calls to extend the base response structure.">
                  <InfoCircleOutlined />
                </Tooltip></Divider>
        {selectedApi.attributes.map((attr, index) => {
          const attrFieldPath = ['attributes', `attr_${index}`];
          const label = (
            <span>
              {attr.name}{' '}
              {attr.description && (
                <Tooltip title={attr.description}  placement="right"    overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
                color="#f5f5f5">
                  <InfoCircleOutlined />
                </Tooltip>
              )}{' '}
              <Text type="secondary">({attr.responseType})</Text>
            </span>
          );
          return (
            <Form.Item
              key={attrFieldPath.join('-')}
              name={attrFieldPath}
              valuePropName="checked"
              initialValue={false}
              style={{ marginBottom: 8 }}
            >
              <Checkbox>{label}</Checkbox>
            </Form.Item>
          );
        })}
      </>
    );
  };

  return (
    <>
      <Form form={form} layout="vertical">
        {renderFormFields()}
        {renderAttributes()}

        <Form.Item>
          <Button
            type="default"
            style={{ marginTop: '10px', width: '100%' }}
            onClick={handlePreviewRequest}
            disabled={isLoading}
          >
            Preview Request
          </Button>
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            style={{ marginTop: '10px', width: '100%' }}
            onClick={handleRunRequest}
            loading={isLoading}
            disabled={isLoading}
          >
            Run Request
          </Button>
        </Form.Item>
      </Form>

      {/* JSON Editor Modal */}
      <Modal
        title={`Edit ${jsonFieldLabel}`}
        visible={jsonModal.visible}
        onOk={handleJsonOk}
        onCancel={handleJsonCancel}
        width={800}
      >
        <ReactAce
          mode="json"
          theme="cobalt"
          name="json_editor"
          onChange={(value) => setJsonValue(value)}
          fontSize={14}
          showPrintMargin
          showGutter
          highlightActiveLine
          value={jsonValue}
          setOptions={{
            showLineNumbers: true,
            tabSize: 2,
            useWorker: false,
          }}
          style={{ width: '100%', height: '600px' }}
        />
      </Modal>
    </>
  );
}


export default ApiForm;


// dede