import React from 'react';
import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-csharp';
import 'ace-builds/src-noconflict/mode-java';
import 'ace-builds/src-noconflict/mode-golang';
import 'ace-builds/src-noconflict/mode-php';
import 'ace-builds/src-noconflict/mode-ruby';
import 'ace-builds/src-noconflict/mode-swift';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-cobalt';

const RequestCodeDisplay = ({ language, codeSnippet, expanded }) => {
  // Determine the appropriate language mode for the Ace Editor
  const languageMode = (() => {
    switch (language) {
      case 'Node':
        return 'javascript';
      case 'Python':
        return 'python';
      case 'C#':
        return 'csharp';
      case 'Java':
        return 'java';
      case 'Go':
        return 'golang';
      case 'PHP':
        return 'php';
      case 'Ruby':
        return 'ruby';
      case 'Swift':
        return 'swift';
      default:
        return 'text'; // fallback for unknown languages
    }
  })();

  return (
    <AceEditor
      mode={languageMode}
      theme="cobalt"
      value={codeSnippet}
      readOnly={true}
      height={expanded ? '560px' : '280px'} 
      width="100%"
      setOptions={{ useWorker: false, wrap: true, printMargin: false  }}
      style={{ borderRadius: '6px', border: '1px solid #ddd', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
    />
  );
};

export default RequestCodeDisplay;
