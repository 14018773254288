import React from 'react';
import AceEditor from 'react-ace';
import { Tabs, Button, message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-cobalt';

const { TabPane } = Tabs;

const ApiResponse = ({ response, expanded }) => {
  const [activeTab, setActiveTab] = React.useState('pretty'); // Track active tab

  const handleCopyResponse = () => {
    const responseText = response ? JSON.stringify(response, null, 2) : ''; // Handle empty response case
    navigator.clipboard.writeText(responseText);
    message.success('Response copied to clipboard'); // Show success message after copying
  };

  const getEditorValue = () => {
    if (!response) {
      return ''; // Empty string when no response
    }
    return activeTab === 'pretty'
      ? JSON.stringify(response, null, 2)
      : JSON.stringify(response);
  };

  // Function to calculate response size in bytes
  const getResponseSize = () => {
    if (!response) {
      return '0 bytes';
    }
    const responseText = JSON.stringify(response);
    const sizeInBytes = new Blob([responseText]).size;

    // Convert bytes to KB or MB for better readability
    if (sizeInBytes < 1024) return `${sizeInBytes} bytes`;
    if (sizeInBytes < 1024 * 1024) return `${(sizeInBytes / 1024).toFixed(2)} KB`;
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  };

  return (
    <div style={{ height: '100%', overflow: 'hidden' }}>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '10px' }}>
        <Tabs
          defaultActiveKey="pretty"
          activeKey={activeTab}
          onChange={(key) => setActiveTab(key)}
        >
          <TabPane tab="Pretty" key="pretty" />
          <TabPane tab="Raw" key="raw" />
        </Tabs>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span style={{ marginRight: '10px', color: '#888' }}>
            Response Size: {getResponseSize()}
          </span>
          <Button
            type="link"
            icon={<CopyOutlined />}
            onClick={handleCopyResponse}
            style={{ float: 'right' }}
          >
            Copy
          </Button>
        </div>
      </div>
      
      <AceEditor
        mode="json"
        theme="cobalt"
        value={getEditorValue()} // Shows response or empty string
        readOnly={true}
        height={expanded ? '560px' : '280px'} // Dynamically change the height based on the expanded state
        width="100%"
        setOptions={{ useWorker: false, wrap: true, printMargin: false  }} // Enable line wrapping
        style={{ borderRadius: '6px', border: '1px solid #ddd', boxShadow: '0 2px 6px rgba(0, 0, 0, 0.1)' }}
      />
    </div>
  );
};

export default ApiResponse;
