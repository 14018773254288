// src/services/apiService.js
import axios from 'axios';

// Define the base URL
const proxyServerUrl = process.env.REACT_APP_PROXY_SERVER_URL; // Proxy server URL

export const apiService = async (url, data = {}, selectedEnv, devIp = '', selectedApi) => {
  try {
    const apiKey = localStorage.getItem('apiKey');
    const playerToken = localStorage.getItem('bearerToken');

    // if (!apiKey || !playerToken) {
    //   throw new Error('API Key or Bearer Token is missing. Please provide valid credentials.');
    // }

    // Extract parameters
    const { query = {}, path = {}, headers = {}, body = {}, formData = {}, attributes = [] } = data;

    // Replace path parameters in the URL
    let fullUrl = `${proxyServerUrl}${url}`;
    for (const [key, value] of Object.entries(path)) {
      fullUrl = fullUrl.replace(`:${key}`, encodeURIComponent(value));
    }

    // Append query parameters to the URL
    const queryString = new URLSearchParams(query).toString();
    if (queryString) {
      fullUrl += `?${queryString}`;
    }

    // Combine headers
    const allHeaders = {
      'Content-Type': 'application/json',
      'Api-Key': apiKey,
      'Authorization': `Bearer ${playerToken}`,
      env: selectedEnv,
      ...(selectedEnv === 'development' && { 'dev-ip': devIp.replace(/^https?:\/\//, '') }),
      ...headers, // Include any custom headers
    };

    let axiosConfig = {
      method: selectedApi.method.toLowerCase(),
      url: fullUrl,
      headers: allHeaders,
    };

    // Prepare request body or form data
    if (Object.keys(formData).length > 0) {
      // Handle form-data
      const form = new FormData();
      for (const [key, value] of Object.entries(formData)) {
        if (value && value.type === 'file') {
          form.append(key, value); // Assuming value is a File object
        } else {
          form.append(key, value);
        }
      }
      axiosConfig.data = form;
      // Override headers with form-data headers
      axiosConfig.headers = {
        ...axiosConfig.headers,
        ...form.getHeaders(),
      };
    } else if (Object.keys(body).length > 0 || attributes.length > 0) {
      // Include attributes if present
      const requestBody = { ...body };
      if (attributes.length > 0) {
        requestBody.attributes = attributes;
      }
      axiosConfig.data = requestBody;
    }

    const response = await axios(axiosConfig);

    console.log('Axios Response:', response);
    return response;
  } catch (error) {
    console.error('API Request Error:', error);
  
    // Capture detailed error response
    if (error.response) {
      console.error('Error Response Data:', error.response.data);
      console.error('Error Status:', error.response.status);
      console.error('Error Headers:', error.response.headers);
  
      // eslint-disable-next-line
      throw {
        message: 'API Request failed',
        status: error.response.status,
        data: error.response.data,
        headers: error.response.headers,
      };
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No Response Received:', error.request);
      throw new Error('No response received from server');
    } else {
      // Something happened in setting up the request that triggered an Error
      throw new Error(error.message);
    }
  }
}  

