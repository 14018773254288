// src/App.js

import React, { useState, useEffect, useRef } from 'react';
import { Layout, Typography, Menu, Select, Card, Button, Input, message, Form, Row, Col, Dropdown, Tooltip, Space } from 'antd';
import ApiForm from './components/ApiForm';
import Joyride, { STATUS } from 'react-joyride';

import ApiList from './components/ApiList';
import ApiResponse from './components/ApiResponse';
import RequestCodeDisplay from './components/RequestCodeDisplay';
import { apiService } from './services/apiService';
import { ExpandOutlined, CompressOutlined, CopyOutlined, ToolOutlined, InfoCircleOutlined } from '@ant-design/icons';
import ApiConfigForm from './components/ApiConfigForm'; // New component for configuration
import axios from 'axios';
import { ConfigProvider } from 'antd';
import { EyeOutlined,RocketOutlined, EyeInvisibleOutlined, ApiOutlined, QuestionCircleOutlined } from '@ant-design/icons'; // Import the icons
import {
  FacebookOutlined,

  InstagramOutlined,
  LinkedinOutlined,
} from '@ant-design/icons';
import './App.css';


const { Header, Content, Footer } = Layout;
const { Title, Paragraph } = Typography;
const { Option } = Select;
const { Text, Link } = Typography;

const menu = (
  <Menu>
    <Menu.Item key="1">
      <a href="https://manual.specterapp.xyz/specter-user-manual" target="_blank" rel="noopener noreferrer">
        Specter Manual
      </a>
    </Menu.Item>
    <Menu.Item key="2">
      <a href="https://doc.specterapp.xyz" target="_blank" rel="noopener noreferrer">
        API Documentation
      </a>
    </Menu.Item>
    <Menu.Item key="3">
      <a href="https://medium.com/specter-chronicles" target="_blank" rel="noopener noreferrer">
        Blogs
      </a>
    </Menu.Item>
    <Menu.Item key="4">
      <a href="mailto:hello@specterapp.xyz" target="_blank" rel="noopener noreferrer">
        Contact Us
      </a>
    </Menu.Item>
    <Menu.Item key="5">
      <a href="https://discord.com/invite/BZSF94yg2K" target="_blank" rel="noopener noreferrer">
        Join Discord Community
      </a>
    </Menu.Item>
    <Menu.Item key="6">
      <a href="https://specter.canny.io/feedback" target="_blank" rel="noopener noreferrer">
        Submit Feedback
      </a>
    </Menu.Item>
  </Menu>
);

function App() {
  const [apiKey, setApiKey] = useState(localStorage.getItem('apiKey') || '');
  const [bearerToken, setBearerToken] = useState(localStorage.getItem('bearerToken') || ''); // Stored as playerToken
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedApi, setSelectedApi] = useState(null);
  const [response, setResponse] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState('Node'); // Default language
  const [selectedEnv, setSelectedEnv] = useState('staging'); // Default to 'staging'
  const [codeSnippet, setCodeSnippet] = useState(''); // Code snippet for the request
  const [requestParams, setRequestParams] = useState({}); // Store the request parameters
  const [editorExpanded, setEditorExpanded] = useState(false); // Editor expanded state
  const [isDevMode, setIsDevMode] = useState(false); // Toggle for Dev Mode
  const [showCredentials, setShowCredentials] = useState(true); // State to control the visibility
  const [apiGroups, setApiGroups] = useState([]); // State to store dynamic API groups
  const [devIp, setDevIp] = useState(localStorage.getItem('devIp') || ''); // State to store development server URL
  const [showDevServerCredentials, setShowDevServerCredentials] = useState(true); // State to control the visibility
  const prevIsDevModeRef = useRef(isDevMode);
  const [isAddingApi, setIsAddingApi] = useState(false);
  const [isEditingApi, setIsEditingApi] = useState(false);
  const [editingApiData, setEditingApiData] = useState(null);
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
  const isProductionMode = process.env.REACT_APP_ENV === 'production';
  const [isRequestExpanded, setIsRequestExpanded] = useState(false);
const SPECTER_ENV_URL = process.env.REACT_APP_SPECTER_ENV_URL;


  // State for Joyride
  const [tourActive, setTourActive] = useState(false);
  const [tourSteps, setTourSteps] = useState([
    {
      target: '.api-key-input',
      content: 'This is where you’ll input your unique API key. Enter it here to authorize and securely access the API. Keep it safe!',
      placement: 'top',
    },
    {
      target: '.api-group-select',
      content: 'Choose an API group to explore the available endpoints.',
      placement: 'top',
    },
    {
      target: '.api-select',
      content: 'Now, select an API endpoint within the chosen group. Each endpoint offers a specific action or resource you can use in your application.',
      placement: 'top',
    },
    {
      target: '.code-snippet-section',
      content: 'Here, you’ll see sample code tailored to your selected API request, ready for you to copy and integrate into your app.',
      placement: 'top',
    },
    {
      target: '.response-section',
      content: 'Here’s where you’ll see the response data after making a request. It’s perfect for verifying your API call results and ensuring everything works as expected.',
      placement: 'top',
    },
  ]);

// Add scroll listener to trigger a Joyride recalculation
useEffect(() => {
  const handleScroll = () => {
    // Force Joyride to recalculate positioning by toggling the state
    setTourSteps((steps) => [...steps]); // This will force Joyride to reposition
  };

  window.addEventListener('scroll', handleScroll);

  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
}, []);


useEffect(() => {
  // Check if the tutorial has been completed before
  const hasCompletedTour = localStorage.getItem('hasCompletedTour');

  if (!hasCompletedTour) {
    // If not, start the tour with a delay
    const timer = setTimeout(() => {
      setTourActive(true);
    }, 3000); // 3000 ms = 3 seconds delay

    return () => clearTimeout(timer); // Clear timeout if the component unmounts
  }
}, []);

const handleJoyrideCallback = (data) => {
  const { status } = data;

  if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
    // Mark tutorial as completed in localStorage
    localStorage.setItem('hasCompletedTour', 'true');
    setTourActive(false); // Stop the tour
  }
};




  useEffect(() => {
    if (prevIsDevModeRef.current === true && isDevMode === false && !selectedGroup) {
      message.info('Please reselect the API group to see the latest updates.');
    }
    prevIsDevModeRef.current = isDevMode;
  }, [isDevMode, selectedGroup]);

  // Toggle function
  const toggleShowCredentials = () => {
    setShowCredentials(!showCredentials);
  };

  // Toggle function
  const toggleShowDevServerCredentials = () => {
    setShowDevServerCredentials(!showDevServerCredentials);
  };

  // Function to save devIp to local storage
  const handleSaveDevIp = () => {
    localStorage.setItem('devIp', devIp); // Save devIp to local storage
    message.success('Development Server URL saved'); // Notify user of the save action
  };

  const customTheme = {
    token: {
      fontFamily: 'Inter, sans-serif',
      colorPrimary: '#2A84FF', // Primary color for your buttons
      colorPrimaryHover: '#1C63CC', // Color when hovering over the button
      colorPrimaryActive: '#15499A', // Color when the button is active or clicked
      borderRadius: '6px', // Customize border radius for a rounded button look
      borderColor: '#2A84FF', // Border color for default and primary buttons
    },
  };

  // Fetch API Groups from the database when the component mounts
  useEffect(() => {
    fetchApiGroups();
  }, []);

  const fetchApiGroups = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/config`);
      // response.data is an array of API configurations
      // We need to group them by 'group' field
      const groupedApis = {};
      response.data.forEach((apiConfig) => {
        const groupName = apiConfig.group;
        if (!groupedApis[groupName]) {
          groupedApis[groupName] = [];
        }
        groupedApis[groupName].push(apiConfig);
      });
      // Convert groupedApis into an array of { group: ..., apis: [...] }
      const apiGroupsArray = Object.keys(groupedApis).map((groupName) => ({
        group: groupName,
        apis: groupedApis[groupName],
      }));
      setApiGroups(apiGroupsArray);
    } catch (error) {
      message.error('Failed to fetch API groups');
    }
  };

  // Inside the App.js handlePreviewRequest function
  const handlePreviewRequest = (params) => {
    setRequestParams(params); // Store the request parameters for generating the code snippet
    generateRequestCodeSnippet(selectedApi.route, params, selectedLanguage, selectedApi); // Pass selectedApi
  };
  
// Save API Key and Bearer Token to localStorage if they are provided
const handleSaveCredentials = () => {
  if (!apiKey && !bearerToken) {
    message.error('Please enter at least one of API Key or Bearer Token');
    return;
  }
  
  if (apiKey) {
    localStorage.setItem('apiKey', apiKey);
  } else {
    localStorage.removeItem('apiKey'); // Remove from localStorage if left blank
  }
  
  if (bearerToken) {
    localStorage.setItem('bearerToken', bearerToken);
  } else {
    localStorage.removeItem('bearerToken'); // Remove from localStorage if left blank
  }

  message.success('API Credentials updated');
};

  const handleGroupChange = (value) => {
    setSelectedGroup(value);
    setSelectedApi(null);
  };

  const handleApiChange = (value) => {
    const group = apiGroups.find((group) => group.group === selectedGroup);
    const apiDetails = group ? group.apis.find((api) => api.name === value) : null;

    setSelectedApi(apiDetails);
    setRequestParams({}); // Clear request parameters
    setCodeSnippet(''); // Clear the request code snippet
    setResponse(null); // Clear the response data
  };


  const toggleEditorSize = () => {
    setEditorExpanded(!editorExpanded);
  };

  const toggleRequestSize = () => {
    setIsRequestExpanded(!isRequestExpanded);
  };
  



  const handleRunRequest = async (params) => {
    setRequestParams(params); // Store the request parameters for generating the code snippet
    try {
      if (selectedApi && selectedApi.route) {
        const requestData = { ...params }; // Prepare request data
        console.log('Making API call with:', requestData);
  
        const response = await apiService(selectedApi.route, requestData, selectedEnv, devIp, selectedApi); // Pass selectedApi
        console.log('API Service Response:', response);
  
        if (response && response.status >= 200 && response.status < 300) {
          console.log('Successful API Response:', response.data);
          setResponse(response.data); // Store the data to state if the call is successful
          generateRequestCodeSnippet(selectedApi.route, requestData, selectedLanguage, selectedApi); // Generate code snippet when request is run
          message.success('API request completed successfully!');
        } else {
          console.error('Unexpected API Response:', response);
          setResponse({ error: 'Unexpected API response received.' });
          message.error(`API request failed with status: ${response?.status} ${response?.statusText}`);
        }
      } else {
        setResponse({ error: 'API route not found. Please select a valid API.' });
        message.error('API route not found. Please select a valid API.');
      }
    } catch (error) {
      console.error('Error during API call:', error);
  
      // If there's a structured error response, use it directly
      if (error.data) {
        setResponse(error.data); // Set the response directly to the structured error response
      } else {
        // Fallback if no structured data is available
        setResponse({ error: 'Failed to fetch data' });
      }
  
      message.error(`Failed to fetch data: ${error.message}`);
    }
  };



  const generateRequestCodeSnippet = (route, params, language, selectedApi) => {
    if (!selectedApi) {
      setCodeSnippet('// Please select an API to generate code snippet.');
      return;
    }
  
    let snippet = '';
  
    // Retrieve API Key and Bearer Token from localStorage
    const apiKeyFromStorage = localStorage.getItem('apiKey') || 'your-api-key';
    const bearerTokenFromStorage = localStorage.getItem('bearerToken') || 'your-bearer-token';
  
    // Extract parameters
    const { query = {}, path = {}, headers = {}, body = {}, formData = {}, attributes = [] } = params;
  
    // Replace path parameters in the route
    let url = SPECTER_ENV_URL +route;
    for (const [key, value] of Object.entries(path)) {
      url = url.replace(`:${key}`, encodeURIComponent(value));
    }
  
    // Append query parameters to the URL
    const queryString = new URLSearchParams(query).toString();
    if (queryString) {
      url += `?${queryString}`;
    }
  
    // Combine headers
    const allHeaders = {
      'Content-Type': 'application/json',
      'Api-Key': apiKeyFromStorage,
      'Authorization': `Bearer ${bearerTokenFromStorage}`,
      ...headers,
    };
  
    // Prepare request body or form data
    let requestBody = {};
    if (Object.keys(formData).length > 0) {
      requestBody = formData;
      // Handle form-data differently in code snippets
    } else if (Object.keys(body).length > 0) {
      requestBody = body;
    }
  
    // Include attributes if present
    if (attributes.length > 0) {
      if (Object.keys(requestBody).length === 0) {
        requestBody = {};
      }
      requestBody.attributes = attributes;
    }
  
    // Generate code snippet based on selected language
    switch (language) {
      case 'Node':
        if (Object.keys(formData).length > 0) {
          snippet = generateNodeFormDataSnippet(url, allHeaders, formData, selectedApi);
        } else {
          snippet = generateNodeSnippet(url, allHeaders, requestBody, selectedApi);
        }
        break;
      case 'Python':
        if (Object.keys(formData).length > 0) {
          snippet = generatePythonFormDataSnippet(url, allHeaders, formData, selectedApi);
        } else {
          snippet = generatePythonSnippet(url, allHeaders, requestBody, selectedApi);
        }
        break;
      case 'cURL':
        snippet = generateCurlSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'C#':
        snippet = generateCSharpSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'Java':
        snippet = generateJavaSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'Go':
        snippet = generateGoSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'PHP':
        snippet = generatePHPSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'Ruby':
        snippet = generateRubySnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      case 'Swift':
        snippet = generateSwiftSnippet(url, allHeaders, requestBody, formData, selectedApi);
        break;
      default:
        snippet = '// Select a language to generate code';
    }
  
    setCodeSnippet(snippet); // Update the code snippet state
  };
  
  const generateNodeSnippet = (url, headers, requestBody, selectedApi) => {
    const method = selectedApi?.method?.toLowerCase() || 'get';
    return `const axios = require('axios');


axios.${selectedApi.method.toLowerCase()}('${url}', ${JSON.stringify(requestBody, null, 2)}, {
  headers: ${JSON.stringify(headers, null, 2)},
})
.then(response => console.log(response.data))
.catch(error => console.error('Error:', error));`;
};

const generateNodeFormDataSnippet = (url, headers, formData, selectedApi) => {
  return `const axios = require('axios');
const FormData = require('form-data');
const fs = require('fs');

const form = new FormData();
${Object.entries(formData)
    .map(([key, value]) => {
      if (value && value.filepath) {
        return `form.append('${key}', fs.createReadStream('${value.filepath}'));`;
      } else {
        return `form.append('${key}', '${value}');`;
      }
    })
    .join('\n')}

axios({
  method: '${selectedApi.method.toLowerCase()}',
  url: '${url}',
  headers: {
    ...form.getHeaders(),
    ${Object.entries(headers)
      .map(([key, value]) => `'${key}': '${value}'`)
      .join(',\n    ')}
  },
  data: form,
})
.then(response => console.log(response.data))
.catch(error => console.error('Error:', error));`;
};

const generatePythonSnippet = (url, headers, requestBody, selectedApi) => {
  return `import requests

url = '${url}'
headers = ${JSON.stringify(headers, null, 2)}

payload = ${JSON.stringify(requestBody, null, 2)}

try:
  response = requests.${selectedApi.method.toLowerCase()}(url, json=payload, headers=headers)
  response.raise_for_status()
  print(response.json())
except requests.exceptions.HTTPError as http_err:
  print(f"HTTP error occurred: {http_err}")
except Exception as err:
  print(f"Other error occurred: {err}")`;
};

const generatePythonFormDataSnippet = (url, headers, formData, selectedApi) => {
  return `import requests

url = '${url}'
headers = ${JSON.stringify(headers, null, 2)}

files = {
  ${Object.entries(formData)
    .map(([key, value]) => {
      if (value && value.filepath) {
        return `'${key}': open('${value.filepath}', 'rb')`;
      } else {
        return `'${key}': (None, '${value}')`;
      }
    })
    .join(',\n  ')}
}

try:
  response = requests.${selectedApi.method.toLowerCase()}(url, files=files, headers=headers)
  response.raise_for_status()
  print(response.json())
except requests.exceptions.HTTPError as http_err:
  print(f"HTTP error occurred: {http_err}")
except Exception as err:
  print(f"Other error occurred: {err}")`;
};

const generateCurlSnippet = (url, headers, requestBody, formData, selectedApi) => {
  const method = selectedApi?.method?.toUpperCase() || 'GET';

  let curlHeaders = '';
  for (const [key, value] of Object.entries(headers)) {
    curlHeaders += `-H '${key}: ${value}' \\\n`;
  }

  if (Object.keys(formData).length > 0) {
    let curlFormData = '';
    for (const [key, value] of Object.entries(formData)) {
      if (value && value.filepath) {
        curlFormData += `-F '${key}=@${value.filepath}' \\\n`;
      } else {
        curlFormData += `-F '${key}=${value}' \\\n`;
      }
    }
    return `curl -X ${method} '${url}' \\\n${curlHeaders}${curlFormData}`;
  } else {
    let curlData = `-d '${JSON.stringify(requestBody, null, 2)}'`;
    return `curl -X ${method} '${url}' \\\n${curlHeaders}${curlData}`;
  }
};

const generateCSharpSnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `using System.Net.Http;
using System.Net.Http.Headers;
using System.Threading.Tasks;

var client = new HttpClient();
var url = "${url}";
var form = new MultipartFormDataContent();

${Object.entries(formData)
      .map(([key, value]) => {
        if (value && value.filepath) {
          return `form.Add(new StreamContent(File.OpenRead("${value.filepath}")), "${key}", "${value.filename ?? 'file'}");`;
        } else {
          return `form.Add(new StringContent("${value}"), "${key}");`;
        }
      })
      .join('\n')}

${Object.entries(headers)
      .map(([key, value]) => `client.DefaultRequestHeaders.Add("${key}", "${value}");`)
      .join('\n')}

var response = await client.${selectedApi.method.toLowerCase()}Async(url, form);
var responseBody = await response.Content.ReadAsStringAsync();
Console.WriteLine(responseBody);`;
  } else {
    return `using System.Net.Http;
using System.Text;
using Newtonsoft.Json;

var client = new HttpClient();
var url = "${url}";
var requestBody = JsonConvert.SerializeObject(${JSON.stringify(requestBody, null, 2)});
var content = new StringContent(requestBody, Encoding.UTF8, "application/json");
${Object.entries(headers)
      .map(([key, value]) => `client.DefaultRequestHeaders.Add("${key}", "${value}");`)
      .join('\n')}
var response = await client.${selectedApi.method.toLowerCase()}Async(url, content);
var responseBody = await response.Content.ReadAsStringAsync();
Console.WriteLine(responseBody);`;
  }
};


const generateGoSnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `package main

import (
  "bytes"
  "fmt"
  "mime/multipart"
  "net/http"
  "os"
)

func main() {
  url := "${url}"
  var b bytes.Buffer
  w := multipart.NewWriter(&b)

  ${Object.entries(formData)
      .map(([key, value]) => {
        if (value && value.filepath) {
          return `file, _ := os.Open("${value.filepath}")
  defer file.Close()
  fw, _ := w.CreateFormFile("${key}", "${value.filename ?? 'file'}")
  io.Copy(fw, file)`;
        } else {
          return `w.WriteField("${key}", "${value}")`;
        }
      })
      .join('\n  ')}

  w.Close()

  req, _ := http.NewRequest("${selectedApi.method.toUpperCase()}", url, &b)
  req.Header.Set("Content-Type", w.FormDataContentType())
  ${Object.entries(headers)
      .map(([key, value]) => `req.Header.Set("${key}", "${value}")`)
      .join('\n  ')}

  client := &http.Client{}
  resp, err := client.Do(req)
  if err != nil {
    panic(err)
  }
  defer resp.Body.Close()
  fmt.Println("Response status:", resp.Status)
}`;
  } else {
    return `package main

import (
  "bytes"
  "fmt"
  "net/http"
)

func main() {
  url := "${url}"
  var jsonStr = []byte(\`${JSON.stringify(requestBody, null, 2)}\`)

  req, err := http.NewRequest("${selectedApi.method.toUpperCase()}", url, bytes.NewBuffer(jsonStr))
  req.Header.Set("Content-Type", "application/json")
  ${Object.entries(headers)
      .map(([key, value]) => `req.Header.Set("${key}", "${value}")`)
      .join('\n  ')}

  client := &http.Client{}
  resp, err := client.Do(req)
  if err != nil {
    panic(err)
  }
  defer resp.Body.Close()
  fmt.Println("Response status:", resp.Status)
}`;
  }
};

const generatePHPSnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => "${url}",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 30,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "${selectedApi.method.toUpperCase()}",
  CURLOPT_POSTFIELDS => http_build_query(${JSON.stringify(formData, null, 2)}),
  CURLOPT_HTTPHEADER => array(
    ${Object.entries(headers)
      .map(([key, value]) => `"${key}: ${value}"`)
      .join(',\n    ')}
  ),
));

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #: " . $err;
} else {
  echo $response;
}
?>`;
  } else {
    return `<?php

$curl = curl_init();

curl_setopt_array($curl, array(
  CURLOPT_URL => "${url}",
  CURLOPT_RETURNTRANSFER => true,
  CURLOPT_ENCODING => "",
  CURLOPT_MAXREDIRS => 10,
  CURLOPT_TIMEOUT => 30,
  CURLOPT_HTTP_VERSION => CURL_HTTP_VERSION_1_1,
  CURLOPT_CUSTOMREQUEST => "${selectedApi.method.toUpperCase()}",
  CURLOPT_POSTFIELDS => json_encode(${JSON.stringify(requestBody, null, 2)}),
  CURLOPT_HTTPHEADER => array(
    "Content-Type: application/json",
    ${Object.entries(headers)
      .map(([key, value]) => `"${key}: ${value}"`)
      .join(',\n    ')}
  ),
));

$response = curl_exec($curl);
$err = curl_error($curl);

curl_close($curl);

if ($err) {
  echo "cURL Error #: " . $err;
} else {
  echo $response;
}
?>`;
  }
};

const generateRubySnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `require 'net/http'
require 'uri'

uri = URI.parse('${url}')
request = Net::HTTP::${selectedApi.method.charAt(0).toUpperCase() + selectedApi.method.slice(1).toLowerCase()}.new(uri)
${Object.entries(headers)
      .map(([key, value]) => `request['${key}'] = '${value}'`)
      .join('\n')}

form_data = [
  ${Object.entries(formData)
      .map(([key, value]) => {
        if (value && value.filepath) {
          return `['${key}', File.open('${value.filepath}')]`;
        } else {
          return `['${key}', '${value}']`;
        }
      })
      .join(',\n  ')}
]

request.set_form form_data, 'multipart/form-data'

req_options = {
  use_ssl: uri.scheme == 'https',
}

response = Net::HTTP.start(uri.hostname, uri.port, req_options) do |http|
  http.request(request)
end

puts response.body`;
  } else {
    return `require 'net/http'
require 'uri'
require 'json'

uri = URI.parse('${url}')
request = Net::HTTP::${selectedApi.method.charAt(0).toUpperCase() + selectedApi.method.slice(1).toLowerCase()}.new(uri)
${Object.entries(headers)
      .map(([key, value]) => `request['${key}'] = '${value}'`)
      .join('\n')}
request.content_type = 'application/json'
request.body = ${JSON.stringify(requestBody, null, 2)}.to_json

req_options = {
  use_ssl: uri.scheme == 'https',
}

response = Net::HTTP.start(uri.hostname, uri.port, req_options) do |http|
  http.request(request)
end

puts response.body`;
  }
};

const generateSwiftSnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `import Foundation

let url = URL(string: "${url}")!
var request = URLRequest(url: url)
request.httpMethod = "${selectedApi.method.toUpperCase()}"

${Object.entries(headers)
      .map(([key, value]) => `request.addValue("${value}", forHTTPHeaderField: "${key}")`)
      .join('\n')}

let boundary = UUID().uuidString
request.setValue("multipart/form-data; boundary=\\(boundary)", forHTTPHeaderField: "Content-Type")

var body = Data()

${Object.entries(formData)
      .map(([key, value]) => {
        if (value && value.filepath) {
          return `let fileData = try Data(contentsOf: URL(fileURLWithPath: "${value.filepath}"))
body.append("--\\(boundary)\\r\\n".data(using: .utf8)!)
body.append("Content-Disposition: form-data; name=\\"${key}\\"; filename=\\"${value.filename ?? 'file'}\\"\\r\\n".data(using: .utf8)!)
body.append("Content-Type: application/octet-stream\\r\\n\\r\\n".data(using: .utf8)!)
body.append(fileData)
body.append("\\r\\n".data(using: .utf8)!)`;
        } else {
          return `body.append("--\\(boundary)\\r\\n".data(using: .utf8)!)
body.append("Content-Disposition: form-data; name=\\"${key}\\"\\r\\n\\r\\n".data(using: .utf8)!)
body.append("${value}\\r\\n".data(using: .utf8)!)`;
        }
      })
      .join('\n')}

body.append("--\\(boundary)--\\r\\n".data(using: .utf8)!)
request.httpBody = body

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  guard let data = data, error == nil else { return }
  print(String(data: data, encoding: .utf8)!)
}
task.resume()`;
  } else {
    return `import Foundation

let url = URL(string: "${url}")!
var request = URLRequest(url: url)
request.httpMethod = "${selectedApi.method.toUpperCase()}"

${Object.entries(headers)
      .map(([key, value]) => `request.addValue("${value}", forHTTPHeaderField: "${key}")`)
      .join('\n')}

let json: [String: Any] = ${JSON.stringify(requestBody, null, 2)}
let jsonData = try? JSONSerialization.data(withJSONObject: json)

request.httpBody = jsonData

let task = URLSession.shared.dataTask(with: request) { data, response, error in
  guard let data = data, error == nil else { return }
  print(String(data: data, encoding: .utf8)!)
}
task.resume()`;
  }
};

const generateJavaSnippet = (url, headers, requestBody, formData, selectedApi) => {
  if (Object.keys(formData).length > 0) {
    return `import java.io.File;
import java.io.IOException;
import okhttp3.*;

public class Main {
  public static void main(String[] args) throws IOException {
    OkHttpClient client = new OkHttpClient();

    MultipartBody.Builder multipartBuilder = new MultipartBody.Builder().setType(MultipartBody.FORM);

    ${Object.entries(formData)
      .map(([key, value]) => {
        if (value && value.filepath) {
          return `multipartBuilder.addFormDataPart("${key}", "${value.filename ?? 'file'}",
            RequestBody.create(new File("${value.filepath}"), MediaType.parse("application/octet-stream")));`;
        } else {
          return `multipartBuilder.addFormDataPart("${key}", "${value}");`;
        }
      })
      .join('\n    ')}

    RequestBody requestBody = multipartBuilder.build();

    Request request = new Request.Builder()
      .url("${url}")
      .method("${selectedApi.method.toUpperCase()}", requestBody)
      ${Object.entries(headers)
        .map(([key, value]) => `.addHeader("${key}", "${value}")`)
        .join('\n      ')}
      .build();

    Response response = client.newCall(request).execute();
    System.out.println(response.body().string());
  }
}`;
  } else {
    return `import java.io.IOException;
import okhttp3.*;

public class Main {
  public static void main(String[] args) throws IOException {
    OkHttpClient client = new OkHttpClient();

    MediaType mediaType = MediaType.parse("application/json");
    RequestBody body = RequestBody.create(${JSON.stringify(requestBody, null, 2)}, mediaType);

    Request request = new Request.Builder()
      .url("${url}")
      .method("${selectedApi.method.toUpperCase()}", body)
      ${Object.entries(headers)
        .map(([key, value]) => `.addHeader("${key}", "${value}")`)
        .join('\n      ')}
      .build();

    Response response = client.newCall(request).execute();
    System.out.println(response.body().string());
  }
}`;
  }
};


const handleLanguageChange = (value) => {
  setSelectedLanguage(value);
  generateRequestCodeSnippet(selectedApi?.route, requestParams, value, selectedApi); // Pass selectedApi
};

const toggleDevMode = () => {
  setIsDevMode(!isDevMode); // Toggle between modes
  setSelectedGroup(null); // Reset the selected group
  setSelectedApi(null); // Reset the selected API
  setIsAddingApi(false); // Reset adding API state
  setIsEditingApi(false); // Reset editing API state
  setEditingApiData(null); // Clear editing API data
  setCodeSnippet(''); // Clear the request code snippet
  setResponse(null); // Clear the response data
};

const handleCopyToClipboard = (text) => {
  navigator.clipboard.writeText(text);
  message.success('Copied to clipboard');
};

// Function to handle adding or editing an API
const handleApiSubmit = (values) => {
  if (isEditingApi) {
    // Update existing API
    axios
      .put(`${API_BASE_URL}/config/${editingApiData._id}`, values)
      .then((response) => {
        message.success('API updated successfully');
        fetchApiGroups(); // Refresh the API groups
        // Reset the states
        setIsAddingApi(false);
        setIsEditingApi(false);
        setEditingApiData(null);
      })
      .catch((error) => {
        console.error('Error updating API:', error);
        message.error('Failed to update API');
      });
  } else {
    // Add new API
    axios
      .post(`${API_BASE_URL}/config`, values)
      .then((response) => {
        message.success('API added successfully');
        fetchApiGroups(); // Refresh the API groups
        // Reset the states
        setIsAddingApi(false);
        setIsEditingApi(false);
        setEditingApiData(null);
      })
      .catch((error) => {
        console.error('Error adding API:', error);
        message.error('Failed to add API');
      });
  }
};

return (
  <ConfigProvider theme={customTheme}>
 <Joyride
  steps={tourSteps}
  continuous
  locale={{   last: "Let's Start Exploring!", }}
  showSkipButton
  run={tourActive}
  styles={{
    options: {
      arrowColor: '#FFFFFF',
      backgroundColor: '#FFFFFF',
      overlayColor: 'rgba(0, 0, 0, 0.6)',
      primaryColor: '#2A84FF',
      textColor: '#333333',
      zIndex: 1000,
    },
    tooltip: {
      padding: '16px',
      borderRadius: '8px',
      boxShadow: '0 4px 16px rgba(0, 0, 0, 0.15)',
      position: 'relative',
    },
    tooltipContainer: {
      textAlign: 'left',
      fontSize: '14px',
      lineHeight: '1.5',
    },
    buttonNext: {
      backgroundColor: '#2A84FF',
      color: '#FFFFFF',
      fontSize: '14px',
      padding: '8px 16px',
      borderRadius: '4px',
      border: 'none',
      boxShadow: 'none',
    },
    buttonBack: {
      color: '#555555',
      fontSize: '14px',
      padding: '8px 16px',
    },
    buttonSkip: {
      color: '#888888',
      fontSize: '14px',
      padding: '8px 16px',
      borderRadius: '6px',
      border: '1px solid #e0e0e0',
    },
    tooltipTitle: {
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '8px',
      color: '#333333',
    },
    tooltipContent: {
      paddingBottom: '12px',
    },
    spotlight: {
      borderRadius: '8px',
      boxShadow: 'none', // No outline
    },
    arrow: {
      display: 'none', // Disable default arrow
    },
  }}
  callback={handleJoyrideCallback}
/>


    <Layout style={{ minHeight: '100vh', backgroundColor: '#f7f9fc' }}>
      <Header
        style={{
          height: '50px',
          backgroundColor: '#272B30',
          padding: '0 20px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          <img src="/images/specter_ghost.png" alt="Specter Logo" style={{ width: '24px', height: '24px' }} />
          <Title style={{ color: '#ffffff', margin: '16px 0' }} level={4}>
            Specter API Sandbox
          </Title>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
          <Dropdown overlay={menu} placement="bottomRight" arrow>
            <Button type="text" icon={<QuestionCircleOutlined style={{ fontSize: '20px', color: '#ffffff' }} />} />
          </Dropdown>
          <Button
      type="default"
      icon={<RocketOutlined />}
      onClick={() => window.open('https://console.specterapp.xyz', '_blank')}
      style={{ backgroundColor: '#f1c40f', color: '#121212' }}
    >
      Launch Specter
    </Button>
    {!isProductionMode && (
  <>
    <Button type="default" icon={<ToolOutlined />} onClick={toggleDevMode}>
      {isDevMode ? 'Back to User Mode' : 'Manage APIs'}
    </Button>
    <Select defaultValue="staging" style={{ width: 150 }} onChange={(value) => setSelectedEnv(value)}>
      <Option value="development">Development</Option>
      <Option value="staging">Staging</Option>
      <Option value="console">Console</Option>
    </Select>
  </>
)}

        </div>
      </Header>
      {!isDevMode && (
        <Card
          bordered={false}
          style={{
            // marginBottom: '20px',
            backgroundColor: '#f0f2f5',
            padding: '8px 16px', // Reduced padding for a thinner appearance
            borderRadius: '12px', // More rounded corners
            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', // Soft shadow for a subtle lift
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ApiOutlined style={{ fontSize: '24px', color: '#2A84FF', marginRight: '10px' }} />
            <Title level={3} style={{ marginBottom: '0', marginTop: '0' }}>
            Welcome! Let's Dive In
            </Title>
          </div>
          <Paragraph style={{ fontSize: '14px', color: '#595959', marginTop: '16px' }}>
            Discover and explore Specter's suite of gaming and gamification APIs. The Specter Sandbox allows you to
            test and integrate powerful tools designed to enhance game development and player engagement. Select an
            API group to start building and level up your gaming experience. To learn more, refer to our detailed
            <a
              href="https://doc.specterapp.xyz"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#2A84FF' }}
            >
              {' '}
              API documentation
            </a>{' '}
            and
            <a
              href="https://manual.specterapp.xyz/specter-user-manual"
              target="_blank"
              rel="noopener noreferrer"
              style={{ color: '#2A84FF' }}
            >
              {' '}
              User Manual
            </a>
            .
          </Paragraph>
        </Card>
      )}

      <Content style={{ padding: '20px 40px', display: 'flex', flexDirection: 'column', gap: '20px' }}>
        {isDevMode ? (
          <>
            {isAddingApi || isEditingApi ? (
              // Show the ApiConfigForm when adding or editing an API
              <ApiConfigForm
                initialValues={editingApiData}
                isEditMode={isEditingApi}
                onSubmit={handleApiSubmit}
                onCancel={() => {
                  // Handle form cancellation
                  setIsAddingApi(false);
                  setIsEditingApi(false);
                  setEditingApiData(null);
                }}
              />
            ) : (
              // Show the API List when not adding or editing
              <ApiList
                onAddApi={() => setIsAddingApi(true)}
                onEditApi={(apiData) => {
                  setIsEditingApi(true);
                  setEditingApiData(apiData);
                }}
              />
            )}
          </>
        ) : (
          <>
            {selectedEnv === 'development' && (
              <>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    marginBottom: '-10px',
                  }}
                >
                  <Button
                    type="text"
                    icon={showDevServerCredentials ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                    onClick={toggleShowDevServerCredentials}
                  >
                    {showDevServerCredentials ? 'Hide Dev Server URL' : 'Show Dev Server URL'}
                  </Button>
                </div>

                {showDevServerCredentials && (
                  <Card title="Development Server URL" bordered={false}>
                    <Form layout="vertical">
                      <Form.Item label="Enter your Development Server URL">
                        <Input
                          placeholder="Enter Development Server URL"
                          value={devIp}
                          onChange={(e) => setDevIp(e.target.value)}
                        />
                      </Form.Item>
                      <Button type="primary" onClick={handleSaveDevIp}>
                        Save
                      </Button>
                    </Form>
                  </Card>
                )}
              </>
            )}

            <div
              style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '-10px' }}
            >
              <Button
                type="text"
                icon={showCredentials ? <EyeInvisibleOutlined /> : <EyeOutlined />}
                onClick={toggleShowCredentials}
              >
                {showCredentials ? 'Hide API Credentials' : 'Show API Credentials'}
              </Button>
            </div>
            {/* API Credentials Card */}
            {showCredentials && (
             <Card className="api-credentials" title="API Credentials" bordered={false}>
                <p style={{ color: '#888', marginTop: '-8px', marginBottom: '10px' }}>
                  Enter your API Key and Bearer Token to authenticate your requests.
                </p>
                <Form layout="vertical">
                  <Row gutter={16}>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <span>
                            API Key&nbsp;
                            <Tooltip
                              title="Used to authenticate API requests from this app. Keep it secure."
                              placement="right"
                              color="#f5f5f5"
                              overlayInnerStyle={{ color: '#000', fontSize: '14px' }} // Customize the inner text style
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                      >
                        <Input
                          placeholder="Enter API Key"
                          className="api-key-input"
                          value={apiKey}
                          onChange={(e) => setApiKey(e.target.value)}
                          suffix={<CopyOutlined onClick={() => handleCopyToClipboard(apiKey)} />}
                        />
                      </Form.Item>
                    </Col>
                    <Col span={12}>
                      <Form.Item
                        label={
                          <span>
                            Bearer Token&nbsp;
                            <Tooltip
                              title="Grants access for the player's session. Keep it secure."
                              placement="right"
                              color="#f5f5f5"
                              overlayInnerStyle={{ color: '#000', fontSize: '14px' }}
                            >
                              <InfoCircleOutlined />
                            </Tooltip>
                          </span>
                        }
                      >
                        <Input
                          placeholder="Enter Bearer Token"
                          value={bearerToken}
                          onChange={(e) => setBearerToken(e.target.value)}
                          suffix={<CopyOutlined onClick={() => handleCopyToClipboard(bearerToken)} />}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                  <Button type="primary" onClick={handleSaveCredentials}>
                    Save
                  </Button>
                </Form>
              </Card>
            )}
            <div style={{ display: 'flex', gap: '20px' }}>
              <div style={{ flex: 1 }}>
                <Card title="API Selection" bordered={false} style={{ marginBottom: '20px' }}>
                  <p style={{ color: '#888', marginTop: '-8px', marginBottom: '10px' }}>
                    Select an API category and a specific API endpoint to interact with.
                  </p>
                  <Select
                   className="api-group-select"
                    placeholder="Select API Category"
                    style={{ width: '100%' }}
                    onChange={handleGroupChange}
                    value={selectedGroup}
                  >
                    {apiGroups.map((group) => (
                      <Option key={group.group} value={group.group}>
                        {group.group}
                      </Option>
                    ))}
                  </Select>
                  <Select
                       className="api-select"
                    placeholder="Select API"
                    style={{ width: '100%', marginTop: '10px' }}
                    onChange={handleApiChange}
                    disabled={!selectedGroup}
                    value={selectedApi ? selectedApi.name : undefined}
                  >
                    {selectedGroup &&
                      apiGroups
                        .find((group) => group.group === selectedGroup)
                        ?.apis?.map((api) => (
                          <Option key={api.name} value={api.name}>
                            {api.name}
                          </Option>
                        ))}
                  </Select>

                  {selectedApi && selectedApi.description && (
                    <div
                      style={{
                        marginTop: '15px',
                        backgroundColor: '#f0f2f5',
                        padding: '10px',
                        borderRadius: '6px',
                      }}
                    >
                      <Paragraph style={{ fontSize: '14px', color: '#595959', marginBottom: '0' }}>
                        {selectedApi.description}
                      </Paragraph>
                    </div>
                  )}
                </Card>

               {selectedApi && (
  <ApiForm
    selectedApi={selectedApi}
    onRunRequest={handleRunRequest}
    onPreviewRequest={handlePreviewRequest}
  />
)}

              </div>
              <div style={{ flex: 1 }}>
              <Card
               className="code-snippet-section"
  title="Request"
  bordered={false}
  style={{ marginBottom: '20px' }}
  extra={
    <Button type="text" onClick={toggleRequestSize}>
      {isRequestExpanded ? <CompressOutlined /> : <ExpandOutlined />} {isRequestExpanded ? 'Collapse' : 'Expand'}
    </Button>
  }
>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: '10px',
                      
                    }}
                  >
                    <Select defaultValue="Node" style={{ width: 120 }} onChange={handleLanguageChange}>
                      <Option value="Node">Node</Option>
                      <Option value="Python">Python</Option>
                      <Option value="cURL">cURL</Option>
                      <Option value="C#">C#</Option>
                      <Option value="Java">Java</Option>
                      <Option value="Go">Go</Option>
                      <Option value="PHP">PHP</Option>
                      <Option value="Ruby">Ruby</Option>
                      <Option value="Swift">Swift</Option>
                    </Select>

                    <Button
                      type="link"
                      icon={<CopyOutlined />}
                      onClick={() => {
                        if (codeSnippet) {
                          navigator.clipboard.writeText(codeSnippet);
                          message.success('Code snippet copied to clipboard');
                        }
                      }}
                      style={{ float: 'right' }}
                    >
                      Copy
                    </Button>
                  </div>
                  <RequestCodeDisplay language={selectedLanguage} codeSnippet={codeSnippet} expanded={isRequestExpanded} />
                </Card>
                <Card
                  title="Response"
                  className="response-section"
                  bordered={false}
                  extra={
                    <Button type="text" onClick={toggleEditorSize}>
                      {editorExpanded ? <CompressOutlined /> : <ExpandOutlined />} {editorExpanded ? 'Collapse' : 'Expand'}
                    </Button>
                  }
                >
                  <ApiResponse response={response} expanded={editorExpanded} />
                </Card>
              </div>
            </div>
          </>
        )}
      </Content>

      <Footer style={{ backgroundColor: '#272B30', color: '#ffffff', padding: '40px 50px' }}>
        <Row justify="space-between" align="middle" gutter={[16, 16]}>
          {/* Left Side: Logo and Description */}
          <Col xs={24} sm={12} md={8} lg={8}>
            <div style={{ marginBottom: '20px' }}>
              {/* Replace with your logo */}
              <img src="/images/specter_logo_2.png" alt="Specter Logo" style={{ width: '150px' }} />
            </div>
            <Text style={{ color: '#ffffff' }}>Specter ©2024 | Created by Dirtcube Interactive</Text>
          </Col>

          {/* Middle: Navigation Links */}
          <Col xs={24} sm={12} md={8} lg={8}>
            <Space direction="vertical" size="small">
              <Link href="/about" style={{ color: '#ffffff' }}>
                About Us
              </Link>
              <Link href="/contact" style={{ color: '#ffffff' }}>
                Contact
              </Link>
              <Link href="/privacy" style={{ color: '#ffffff' }}>
                Privacy Policy
              </Link>
              <Link href="/terms" style={{ color: '#ffffff' }}>
                Terms of Service
              </Link>
            </Space>
          </Col>

          {/* Right Side: Social Media Icons */}
          <Col xs={24} sm={24} md={8} lg={8} style={{ textAlign: 'right' }}>
            <Space size="large">
              <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer">
                <FacebookOutlined style={{ fontSize: '24px', color: '#ffffff' }} />
              </a>
              <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
  <img 
    src={`${process.env.PUBLIC_URL}/images/x.svg`} 
    alt="Twitter" 
    style={{ width: '24px', height: '22px', filter: 'invert(1)' }} 
  />
</a>


              <a href="https://www.instagram.com" target="_blank" rel="noopener noreferrer">
                <InstagramOutlined style={{ fontSize: '24px', color: '#ffffff' }} />
              </a>
              <a href="https://www.linkedin.com" target="_blank" rel="noopener noreferrer">
                <LinkedinOutlined style={{ fontSize: '24px', color: '#ffffff' }} />
              </a>
            </Space>
          </Col>
        </Row>
      </Footer>
    </Layout>
  </ConfigProvider>
);
}

export default App;